import React, {useEffect, useRef, useState} from "react";
import { useParams } from 'react-router-dom';
import Spinner from "../Spinner/Spinner";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import {Button, Container} from "react-bootstrap";
import Url from "../../Url/Url";

const apiServer = Url;

const Question = props => {

    const [showSpinner,setShowSpinner] = useState(false);

    const {hotel} = useParams();
    const [questionClassList,setQuestionClassList] = useState([]);
    const [questionList,setQuestionList] = useState([]);
    const [hotelList,setHotelList] = useState([]);
    let question_hotel = useRef(null);
    let question_questionClass = useRef(null);

    useEffect(()=>{

        axios.get(`${apiServer}/smartBooking/smartBooking_loadHotelsList`).then((data) =>{
            if(data.status === 200){
                if(data.data){
                    setHotelList(data.data);
                    setShowSpinner(false);
                    loadQuestionClassList(data.data[0].hotel_id);
                    loadQuestionList(data.data[0].hotel_id,"0");
                }
            }else{
                console.log("Server Error")
            }
        });

        // console.log(question_hotel.current.value);

    },[]);

    let loadQuestionClassList = (hotel,questionClass)=>{
        axios.post(`${apiServer}/smartBooking/loadQuestionClassList`,{
            itemId1:hotel
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length){
                    // console.log(data.data)
                    setQuestionClassList(data.data);
                }else{
                }
            }else{
                console.log("Server Error")
            }
        });
    }

    let loadQuestionList = (hotel,questionClass)=>{
        axios.post(`${apiServer}/smartBooking/loadQuestionList`,{
            itemId1:hotel,itemId2:questionClass
        }).then((data) =>{
            if(data.status === 200){
                // if(data.data.length){
                    setQuestionList(data.data);
                // }else{
                // }
            }else{
                console.log("Server Error")
            }
        });
    }


    return (
        <Container fluid style={{maxWidth:"800px",marginTop:"6em"}}>
            {
                showSpinner && <Spinner/>
            }
            <div className={"row"}>
                <div className="col-md-6 search mb-3">
                    <label className={"label"}>飯店</label>
                    <select id={"question_hotel"} className={"form-select"} defaultValue={hotel} ref={question_hotel}
                            onChange={(e)=>{
                                // console.log(e.target.value);
                                loadQuestionClassList(e.target.value);
                                loadQuestionList(e.target.value,"0");
                            }}
                    >
                        {
                            hotelList.map((item,index)=>(
                                <option key={item.hotel_id} value={item.hotel_id}>{item.hotel_name}</option>
                            ))
                        }
                    </select>
                    <span style={{"color":"red","fontWeight":"800"}}>(請確認您欲查詢的分館)</span>
                </div>
                <div className="col-md-6 search mb-3">
                    <label className={"label"}>常見問題類別</label>
                    <select id={"question_questionClass"} className={"form-select"} defaultValue={"0"} ref={question_questionClass}
                            onChange={(e)=>{
                                // console.log(e.target.value);
                                loadQuestionList(question_hotel.current.value,e.target.value);
                            }}
                    >
                        {
                            questionClassList.map((item,index)=>(
                            <option key={item.questionClassId} value={item.questionClassId}>{item.questionClassName}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <Accordion defaultActiveKey={0}>
                {
                    // console.log(questionList)
                    questionList.map((item,index)=>(
                    <Accordion.Item key={item.questionId} eventKey={index}>
                    <Accordion.Header>{item.questionContent}</Accordion.Header>
                    <Accordion.Body>{item.answerContent}</Accordion.Body>
                    </Accordion.Item>
                    ))
                }
            </Accordion>
        </Container>
    );
}

export default Question;