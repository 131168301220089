import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Button, Carousel, Container, Form, Modal, Tab, Tabs} from "react-bootstrap";
import Spinner from "../Spinner/Spinner";
import BookingDetailModal from "../BookingDetailModal/BookingDetailModal";
import ProjectDetailModal from "../ProjectDetailModal/ProjectDetailModal";
import RestBookingDetailModal from "../RestBookingDetailModal/RestBookingDetailModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBed,
    faCalendarXmark,
    faCircleExclamation,
    faClock,
    faMoon,
    faPerson
} from "@fortawesome/free-solid-svg-icons";
import HotelList from "../HotelList/HotelList";
import RoomTypeList from "../RoomTypeList/RoomTypeList";
import ProjectList from "../ProjectList/ProjectList";
import RestHoursList from "../RestHoursList/RestHoursList";
import Url from "../../Url/Url";

const apiServer = Url;

let availableSelect_project = '';
let availableSelect_date = '';
let no = '';

const Home = props => {

    const [showSpinner,setShowSpinner] = useState(false);

    const [hotelList,setHotelList] = useState([]);
    const [searchType,setSearchType] = useState(0);
    const [roomTypeList,setRoomTypeList] = useState([]);
    const [showNoData,setShowNoData] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [roomTypeDetailData,setRoomTypeDetailData] = useState([]);
    const [showConfirmModal,setShowConfirmModal] =useState(false);


    const [showProjectDetailModal, setShowProjectDetailModal] = useState(false);
    const [projectList,setProjectList] = useState([]);
    const [projectDetailData,setProjectDetailData] = useState([]);
    const [showAvailableCalendarModal,setShowAvailableCalendarModal] = useState(false);
    const [calendarData,setCalendarData] = useState([]);
    const [showCheckItemModal,setShowCheckItemModal] = useState(false);
    const [checkItemData,setCheckItemData] = useState([]);
    const [showCheckInDetail,setShowCheckInDetail] = useState(false);
    const [countiesList,setCountiesList] = useState([]);


    const [restBookingList,setRestBookingList] = useState([]);
    const [showRestBookingDetailModal, setShowRestBookingDetailModal] = useState();
    const [restBookingDetailData,setRestBookingDetailData] = useState([]);


    const [showCheckout,setShowCheckout] = useState(false);
    const [checkoutPara,setCheckoutPara] = useState({});

    let booking_districtRef = useRef(null);
    let booking_hotelRef = useRef(null);
    let booking_priceOrderRef = useRef(null);
    let booking_peopleRef = useRef(null);
    let booking_checkInRef = useRef(null);
    let booking_checkOutRef = useRef(null);

    let project_hotelRef = useRef(null);
    let project_priceOrderRef = useRef(null);
    let queryAvailable_hotelRef = useRef(null);
    let queryAvailable_monthRef = useRef(null);

    let restBooking_districtRef = useRef(null);
    let restBooking_hotelRef = useRef(null);
    let restBooking_checkInDateRef = useRef(null);
    let restBooking_checkInTimeRef = useRef(null);


    let checkout_MIDRef = useRef(null);
    let checkout_VersionRef = useRef(null);
    let checkout_TradeInfoRef = useRef(null);
    let checkout_TradeShaRef = useRef(null);

    const updateSearchType = (type)=>{
        setSearchType(type);
    };

    const changeShowDetailModal = (result) => setShowDetailModal(result);

    const changeShowProjectDetailModal = (result) => setShowProjectDetailModal(result);

    const changeShowRestBookingDetailModal = (result) => setShowRestBookingDetailModal(result);

    const booking_search = ()=>{
        if(booking_districtRef.current.value == "0"){
            setShowNoteModal(true);
        }else{
            setShowNoteModal(false);
            setShowSpinner(true);
            axios.post(`${apiServer}/smartBooking/smartBooking_searchRoomTypeList`,{
                itemId1:booking_districtRef.current.value,
                itemId2:booking_hotelRef.current.value,
                itemId3:booking_priceOrderRef.current.value,
                itemId4:booking_peopleRef.current.value,
                itemId5:booking_checkInRef.current.value,
                itemId6:booking_checkOutRef.current.value,
            }).then((data) =>{
                if(data.status === 200){
                    if(data.data.length != 0){
                        setShowNoData(false);
                        setSearchType(1);
                        setRoomTypeList(data.data);
                        setShowSpinner(false);
                    }else{
                        setShowNoData(true);
                        setSearchType(0);
                        setShowSpinner(false);
                    }
                }else{
                    console.log("Server Error")
                }
            });
        }
    }

    const project_search = ()=>{
        setShowSpinner(true);
        axios.post(`${apiServer}/smartBooking/smartBooking_loadProjectList`,{
            itemId1:project_hotelRef.current.value,
            itemId2:project_priceOrderRef.current.value,
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length != 0){
                    setShowNoData(false);
                    setSearchType(2);
                    setProjectList(data.data);
                    setShowSpinner(false);
                }else{
                    setShowNoData(true);
                    setSearchType(0);
                    setShowSpinner(false);
                }
            }else{
                console.log("Server Error")
            }
        });
    }

    const restBooking_search = ()=>{

        setShowSpinner(true);
        axios.post(`${apiServer}/smartBooking/smartBooking_searchRestBookingList`,{
            itemId1:restBooking_districtRef.current.value,
            itemId2:restBooking_hotelRef.current.value,
            itemId3:restBooking_checkInDateRef.current.value,
            itemId4:restBooking_checkInTimeRef.current.value,
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length != 0){
                    setShowNoData(false);
                    setSearchType(3);
                    setRestBookingList(data.data);
                    setShowSpinner(false);
                }else{
                    setShowNoData(true);
                    setSearchType(0);
                    setShowSpinner(false);
                }
            }else{
                console.log("Server Error")
            }
        });
    };

    const booking_detail = (id,bed,night,totalPrice,checkInDate,checkOutDate)=>{
        axios.post(`${apiServer}/smartBooking/smartBooking_loadRoomTypeDetail`,{
            itemId1:id,itemId2:checkInDate,itemId3:checkOutDate
        }).then((data) =>{
            if(data.status === 200){
                setShowDetailModal(true);
                setRoomTypeDetailData([{
                    ...data.data[0],
                    bed,
                    night,
                    totalPrice,
                }])
            }else{
                console.log("Server Error")
            }
        });
    }

    const project_detail = (id)=>{
        axios.post(`${apiServer}/smartBooking/smartBooking_loadProjectDetail`,{
            itemId1:id
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length != 0){
                    setShowProjectDetailModal(true);
                    setProjectDetailData([data.data[0]])
                }else{

                }
            }else{
                console.log("Server Error")
            }
        });
    }

    const restBooking_detail = (hotelId,hour,checkInDate,checkInTime,price)=>{
        setShowSpinner(true);
        axios.post(`${apiServer}/smartBooking/smartBooking_loadRestBookingDetail`,{
            itemId1:hotelId,
            itemId2:hour,
            itemId3:checkInDate,
            itemId4:checkInTime,
            itemId5:price,
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length != 0){
                    setShowRestBookingDetailModal(true);
                    setRestBookingDetailData([data.data[0]])
                    setShowSpinner(false);
                }else{
                    setShowSpinner(false);
                }
            }else{
                console.log("Server Error")
            }
        });
    }

    const loadHotelList = ()=>{
        setShowSpinner(true);
        axios.get(`${apiServer}/smartBooking/smartBooking_loadHotelsList`).then((data) =>{

            if(data.status === 200){
                setHotelList(data.data);
                setShowSpinner(false);
            }else{
                console.log("Server Error")
            }
        });
    };

    const loadAvailableCalendar = (projectId,checkInDate,hotelId)=>{
        setShowSpinner(true);
        axios.post(`${apiServer}/smartBooking/smartBooking_loadAvailableCalendar`,{
            itemId1:projectId,itemId2:checkInDate,itemId3:hotelId
        }).then((data) =>{

            if(data.status === 200){
                setCalendarData([data.data]);
                setShowSpinner(false);
                setShowAvailableCalendarModal(true);
            }else{
                console.log("Server Error")
            }
        });
    }

    const availableSelect = (e)=>{
        if (e.target.classList.contains('availableDay')) {

            availableSelect_project = e.target.dataset.project;
            availableSelect_date = e.target.dataset.date;
            if(e.target.dataset.type == 1 || e.target.dataset.type == 2){
                loadCheckItemData(2,availableSelect_project,availableSelect_date);
            }else{
                // if(e.target.dataset.multiplesale == 1){
                //     setShowConfirmModal(true);
                // }else{
                //     loadCheckItemData(0,availableSelect_project,availableSelect_date);
                // }
                loadCheckItemData(0,availableSelect_project,availableSelect_date);
            }
        }
    }

    const loadCheckItemData = (multiplesale,availableSelect_project,availableSelect_date)=>{
        setShowSpinner(true);
        axios.post(`${apiServer}/smartBooking/smartBooking_loadCheckItemData`,{
            itemId1:multiplesale,itemId2:availableSelect_project,itemId3:availableSelect_date
        }).then((data) =>{

            if(data.status === 200){
                if(data.data.length != 0){
                    setCheckItemData(data.data);
                    setShowCheckItemModal(true);
                    setShowSpinner(false);
                }else{
                    setShowSpinner(false);
                }
            }else{
                console.log("Server Error")
            }
        });
    }

    const bookIn = (type,hotelId,...other)=>{
        setShowSpinner(true);
        no = '';
        axios.get(`${apiServer}/smartBooking/smartBooking_loadCountiesList`)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data.length != 0){
                        setCountiesList(response.data);
                    }else{
                    }
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });
        switch (type){
            case 1:
                setCheckoutPara([{
                    type:1,
                    hotelId,
                    room_type_id:other[0],
                    checkInDatetime:other[1],
                    checkOutDatetime:other[2],
                    room_type_name:other[3],
                    room_type_sleep:other[4],
                    room_total_price:other[5],
                    night:other[6],
                }])

                setSearchType(999);
                setShowCheckout(true);
                setShowSpinner(false);

                // axios.get(`${apiServer}/smartBooking/smartBooking_loadCountiesList`)
                //     .then((response) =>{
                //         if(response.status === 200){
                //             if(response.data.length != 0){
                //                 console.log(response.data)
                //                 setCountiesList(response.data);
                //             }else{
                //             }
                //         }else{
                //             console.log("Server Error")
                //         }
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });

                axios.post(`${apiServer}/smartBooking/smartBooking_checkout`,{
                    itemId1:{
                        type:1,
                        hotelId,
                        room_type_id:other[0],
                        checkInDatetime:other[1],
                        checkOutDatetime:other[2],
                        room_type_name:other[3],
                        room_type_sleep:other[4],
                        room_total_price:other[5],
                        night:other[6],
                    }
                }).then((data) =>{
                    if(data.status === 200){
                        if(data.data.length != 0){
                            no = data.data.No;
                            checkout_MIDRef.current.value = data.data.MerchantID;
                            checkout_VersionRef.current.value = data.data.Version;
                            checkout_TradeInfoRef.current.value = data.data.TradeInfo;
                            checkout_TradeShaRef.current.value = data.data.TradeSha;
                        }else{
                        }
                    }else{
                        console.log("Server Error")
                    }
                });


                // checkout(checkoutPara);

                window.document.body.scrollTop = 0;
                window.document.documentElement.scrollTop = 0;
                break;
            case 2:
                let project_id = other[0],changeHotel = other[1],hotelSelect = other[2],timeSelect = other[3],project_type = other[4];
                let hotelSelectArray = [];

                if(changeHotel === 1){
                    for (let i=0;i<hotelSelect.length;i++){
                        hotelSelectArray.push({
                            checkInDatetime:hotelSelect[i].dataset.date1,
                            checkOutDatetime:hotelSelect[i].dataset.date3,
                            hotelName:hotelSelect[i].options[hotelSelect[i].selectedIndex].text,
                            hotelId:hotelSelect[i].value,
                        })
                    }
                }

                // axios.get(`${apiServer}/smartBooking/smartBooking_loadCountiesList`)
                //     .then((response) =>{
                //         if(response.status === 200){
                //             if(response.data.length != 0){
                //                 setCountiesList([response.data[0]]);
                //             }else{
                //             }
                //         }else{
                //             console.log("Server Error")
                //         }
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });

                axios.post(`${apiServer}/smartBooking/smartBooking_loadProjectDetail`,{
                    itemId1:project_id,itemId2:checkItemData[0].checkInDatetime,itemId3:timeSelect
                }).then((data) =>{
                    if(data.status === 200){
                        if(data.data.length != 0){
                            setCheckoutPara([{
                                type:2,
                                hotelId,
                                project_introduce:data.data[0].project_introduce,
                                project_name:data.data[0].project_name,
                                project_price:data.data[0].project_price,
                                project_content:data.data[0].project_content,
                                project_id:checkItemData[0].project_id,
                                hotelSelect:hotelSelectArray,
                                changeHotel,
                                checkInDatetime: data.data[0].checkInDate,
                                checkOutDatetime: data.data[0].checkOutDate,
                                timeSelect,
                                project_type,
                                project_value:checkItemData[0].project_value,
                                hotel_name:data.data[0].hotel_name
                            }])
                            setSearchType(999);
                            setShowCheckout(true);
                            setShowSpinner(false);

                            axios.post(`${apiServer}/smartBooking/smartBooking_checkout`,{
                                itemId1:{
                                    type:2,
                                    hotelId,
                                    project_introduce:data.data[0].project_introduce,
                                    project_name:data.data[0].project_name,
                                    project_price:data.data[0].project_price,
                                    project_content:data.data[0].project_content,
                                    hotelSelect:hotelSelectArray,
                                    changeHotel,
                                    checkInDatetime: data.data[0].checkInDate,
                                    checkOutDatetime: data.data[0].checkOutDate,
                                    timeSelect,
                                    project_type,
                                    project_value:checkItemData[0].project_value,
                                    hotel_name:data.data[0].hotel_name
                                }
                            }).then((data2) =>{
                                if(data2.status === 200){
                                    if(data2.data.length != 0){
                                        no = data2.data.No;
                                        checkout_MIDRef.current.value = data2.data.MerchantID;
                                        checkout_VersionRef.current.value = data2.data.Version;
                                        checkout_TradeInfoRef.current.value = data2.data.TradeInfo;
                                        checkout_TradeShaRef.current.value = data2.data.TradeSha;
                                    }else{
                                    }
                                }else{
                                    console.log("Server Error")
                                }
                            });

                            // checkout();

                            window.document.body.scrollTop = 0;
                            window.document.documentElement.scrollTop = 0;
                        }else{
                            setShowSpinner(false);
                        }
                    }else{
                        console.log("Server Error")
                    }
                });
                break;
            case 3:
                console.log(type,hotelId,...other);
                axios.post(`${apiServer}/smartBooking/smartBooking_loadRestBookingDetail`,{
                    itemId1:hotelId,
                    itemId2:other[0],
                    itemId3:other[1],
                    itemId4:other[2],
                    itemId5:other[3],
                }).then((data) =>{
                    if(data.status === 200){
                        if(data.data.length != 0){
                            setCheckoutPara([{
                                type:3,
                                hotelId,
                                checkInDatetime:data.data[0].checkInDatetime,
                                checkOutDatetime:data.data[0].checkOutDatetime,
                                hours:data.data[0].hours,
                                price:data.data[0].price,
                            }])
                            setSearchType(999);
                            setShowCheckout(true);
                            setShowSpinner(false);

                            axios.post(`${apiServer}/smartBooking/smartBooking_checkout`,{
                                itemId1:{
                                    type:3,
                                    hotelId,
                                    checkInDatetime:data.data[0].checkInDatetime,
                                    checkOutDatetime:data.data[0].checkOutDatetime,
                                    hours:data.data[0].hours,
                                    price:data.data[0].price,
                                }
                            }).then((data2) =>{
                                if(data2.status === 200){
                                    if(data2.data.length != 0){
                                        no = data2.data.No;
                                        checkout_MIDRef.current.value = data2.data.MerchantID;
                                        checkout_VersionRef.current.value = data2.data.Version;
                                        checkout_TradeInfoRef.current.value = data2.data.TradeInfo;
                                        checkout_TradeShaRef.current.value = data2.data.TradeSha;
                                    }else{
                                    }
                                }else{
                                    console.log("Server Error")
                                }
                            });

                            // checkout();

                            window.document.body.scrollTop = 0;
                            window.document.documentElement.scrollTop = 0;
                        }else{
                            setShowSpinner(false);
                        }
                    }else{
                        console.log("Server Error")
                    }
                });
                break;
        }
    }

    let handleInvoiceSelect = (e)=>{

        switch (e.target.value){
            case "1":
                document.getElementById("invoiceInputWrap2").classList.add("display_none");
                break;
            case "2":
                document.getElementById("invoiceInputWrap2").classList.remove("display_none");
                break;
        }
    }

    let checkInDate = new Date();
    checkInDate = checkInDate.toISOString().substring(0,10);

    let checkOutDate = new Date();
    checkOutDate.setDate(checkOutDate.getDate() + 1);
    checkOutDate = checkOutDate.toISOString().substring(0,10);

    useEffect(()=>{
        loadHotelList();
    },[]);

    return (

        <Container className={"bg-white p-0"} fluid="xxl">
            {
                showSpinner && <Spinner/>
            }

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showNoteModal}
                dialogClassName="noteModal"
            >
                <Modal.Body>
                    <div style={{border:"3px solid rgb(106, 218, 169)",borderRadius:"10px",height:"200px",width:"",textAlign:"center",margin: "10px",}}>
                        <span style={{color: "rgb(106, 218, 169)",backgroundColor: "white",fontSize:"1.3em",width: "50px",position: "absolute",top:"12px",right: 0,left: 0,margin: "auto"}}>提醒</span>
                        <div id={"noteModal_title1"} style={{fontSize: "1.8em",lineHeight: "200px"}}>您忘記選取<span style={{color:"rgb(106, 218, 169)"}}>地區</span>了</div>
                        <div style={{position: "absolute",bottom:"12px",right: 0,left: 0,margin: "auto"}}>
                            <Button id={"noteModal_close"} style={{width:"4em"}} className={"closeBtn"} onClick={()=>setShowNoteModal(false)}>關閉</Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmModal}
                // dialogClassName="booking-modal"
            >
                <Modal.Body>
                    <div style={{border:"3px solid rgb(106, 218, 169)",borderRadius:"10px",height:"200px",width:"",textAlign:"center",margin: "10px",}}>
                        <span style={{color: "rgb(106, 218, 169)",backgroundColor: "white",fontSize:"1.3em",width: "50px",position: "absolute",top:"12px",right: 0,left: 0,margin: "auto"}}>提醒</span>
                        <div style={{fontSize: "1.8em",lineHeight: "200px"}}>是否入住不同館別？</div>
                        <div style={{position: "absolute",bottom:"12px",right: 0,left: 0,margin: "auto"}}>
                            <Button style={{width:"4em"}} className={"mainBtn"} onClick={()=> {
                                setShowConfirmModal(false);
                                loadCheckItemData(1,availableSelect_project,availableSelect_date);

                            }}>是</Button>&nbsp;&nbsp;
                            <Button style={{width:"4em"}} className={"closeBtn"} onClick={()=> {
                                setShowConfirmModal(false);
                                loadCheckItemData(0,availableSelect_project,availableSelect_date);
                            }}>否</Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            {
                showDetailModal && <BookingDetailModal roomTypeDetailData={roomTypeDetailData} showDetailModal={showDetailModal} onChangeShowDetailModal={changeShowDetailModal} checkInDate={booking_checkInRef.current.value} checkOutDate={booking_checkOutRef.current.value} onBookIn={bookIn}/>
            }

            {
                showProjectDetailModal && <ProjectDetailModal projectDetailData={projectDetailData} showProjectDetailModal={showProjectDetailModal} onChangeShowProjectDetailModal={changeShowProjectDetailModal} />
            }

            <Modal
                id={"roomAvailableModal"}
                size="lg"
                show={showAvailableCalendarModal}
                onHide={()=>setShowAvailableCalendarModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{paddingTop:"0px"}}>
                    {
                        calendarData && calendarData.map((item,index)=>(
                            <div key={index} className={"row"}>
                                {/*{item.multipleSale}*/}
                                {item[0].multipleSale == 1 && <div className={"col-12"} style={{fontWeight:800,textAlign:"center",color:"red",margin:"10px 0px"}}>若欲入住不同飯店，請先查詢空房再預訂</div>}
                                <div className={"col-12"} style={{fontWeight:800,textAlign:"center",color:"red",margin:"10px 0px"}}>請點選日期</div>
                                <div className={"col-12"}>
                                    <div className={"row"}>
                                        <div className="col-md-6 search" style={{margin:"10px 0px"}}>
                                            <label className={"label"}>飯店</label>
                                            <Form.Select defaultValue={item[0].defaultHotel} ref={queryAvailable_hotelRef}>
                                                {/*<option value="0">飯店</option>*/}
                                                {
                                                    item[0].project_hotelList.map((item2,index2)=>(
                                                        <option key={item2.hotel_id} value={item2.hotel_id}>{item2.hotel_name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-6 search" style={{margin:"10px 0px"}}>
                                            <label className={"label"}>月份</label>
                                            <Form.Select
                                                defaultValue={item[0].defaultMonth}
                                                ref={queryAvailable_monthRef}
                                                onChange={(e)=>loadAvailableCalendar(item[0].project_id,e.target.value,queryAvailable_hotelRef.current.value)}
                                            >
                                                {
                                                    item[0].project_monthList.map((item3,index3)=>(
                                                        <option key={item3.month_id} value={item3.month_id}>{item3.month_value}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>

                                <div dangerouslySetInnerHTML={{__html:item[0].calendarStr1}} className="table-responsive col-12 availableCalendar" onClick={(e)=>availableSelect(e)}>

                                </div>

                                <div dangerouslySetInnerHTML={{__html:item[0].calendarStr2}} className="table-responsive col-12 availableTable" onClick={(e)=>availableSelect(e)}>

                                </div>
                                <div className={"mt-4 mb-3"} style={{textAlign:"center"}}>
                                    <Button
                                        className={"closeBtn"}
                                        style={{width:"7em",margin:"0px 5px"}}
                                        onClick={()=>setShowAvailableCalendarModal(false)}
                                    >關閉</Button>
                                </div>
                            </div>
                        ))
                    }

                </Modal.Body>
            </Modal>

            <Modal
                id={"checkItemModal"}
                size="lg"
                show={showCheckItemModal}
                onHide={()=>setShowCheckItemModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{paddingTop:"0px"}}>
                    {

                        checkItemData.map((item,index)=>(
                            <div key={index}>
                                <h3 style={{borderBottom: "5px solid black"}}>{item.project_name}</h3>

                                {
                                    (item.project_type === 1 || item.project_type === 2) &&
                                    <div className={"table-responsive col-12 sameHotelTable"}>
                                        <table className={"table table-bordered mt-3"}>
                                            <thead>
                                            <tr className={"d-flex theHeader"}>
                                                <th scope="col" className={"col-4"}>入住日期</th>
                                                <th scope="col" className={"col-4"}>入住時間</th>
                                                <th scope="col" className={"col-4"}>入住飯店</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={"d-flex"}>
                                                <td className={"col-4"}>{item.checkInDatetime}</td>
                                                <td className={"col-4"}>
                                                    <div className="search">
                                                        {/*<input*/}
                                                        {/*    ref={restBooking_checkInTimeRef}*/}
                                                        {/*    placeholder=""*/}
                                                        {/*    className={"form-control form-item"}*/}
                                                        {/*    // defaultValue={new Date().toTimeString().slice(0, 5)}*/}
                                                        {/*    type="time"*/}
                                                        {/*    onFocus={(e)=> {*/}
                                                        {/*        // e.target.type = "time";*/}
                                                        {/*        // e.target.step = 1800;*/}
                                                        {/*        console.log(new Date())*/}
                                                        {/*        console.log(new Date().toTimeString().slice(0, 5))*/}
                                                        {/*    }}*/}
                                                        {/*    onBlur={(e)=>(e.target.type = "text")}*/}
                                                        {/*    id="checkOut"/>*/}
                                                        <input
                                                            placeholder=""
                                                            className={"form-control form-item"}
                                                            type="text"
                                                            max={"20:00"}
                                                            onFocus={(e)=>{
                                                                e.target.type = "time";
                                                                e.target.max = "20:00";
                                                                // console.log(new Date())
                                                                // console.log(new Date().toTimeString().slice(0, 5))
                                                            }}
                                                            onBlur={(e)=>(e.target.type = "text")}
                                                            id="checkItem_timesSelect"/>
                                                    </div>
                                                </td>
                                                <td className={"col-4"}>{item.hotelName}</td>
                                            </tr>
                                            <tr className={"d-flex"}>
                                                <td className={"col-12"} style={{color:"red"}}>請選擇入住時間</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {
                                    (item.project_type !== 1 || item.project_type !== 2) && item.changeHotel === 0 &&
                                    <div className={"table-responsive col-12 sameHotelTable"}>
                                        <table className={"table table-bordered mt-3"}>
                                            <thead>
                                            <tr className={"d-flex theHeader"}>
                                                <th scope="col" className={"col-4"}>入住日期</th>
                                                <th scope="col" className={"col-4"}>退房日期</th>
                                                <th scope="col" className={"col-4"}>入住飯店</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={"d-flex"}>
                                                <td className={"col-4"}>{item.checkInDatetime}</td>
                                                <td className={"col-4"}>{item.checkOutDatetime}</td>
                                                <td className={"col-4"}>{item.hotelName}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                }

                                {
                                    (item.project_type !== 1 || item.project_type !== 2) && item.changeHotel === 1 &&
                                    <div className={"table-responsive col-12 differentHotelTable"}>
                                        <table className={"table table-bordered mt-3"}>
                                            <thead>
                                            <tr className="d-flex theHeader">
                                                <th scope="col" className={"col-6"}>入住日期</th>
                                                <th scope="col" className={"col-6"}>入住飯店</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                item.project_dateList.map((item2,index2)=>(
                                                    <tr key={index2} className="d-flex">
                                                        <td className={"col-6"}>{item2.date1}</td>
                                                        <td className={"col-6"}>
                                                            <Form.Select id={item2.date2} className={"checkItem_hotelSelect"} data-date1={item2.date1} data-date3={item2.date3}>
                                                                {
                                                                    item.project_hotelList.map((item3,index3)=>(
                                                                        <option key={index3} value={item3.hotel_id}>{item3.hotel_name}</option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                }
                                <div className={"mt-4 mb-3"} style={{textAlign:"center"}}>
                                    <Button
                                        className={"closeBtn"}
                                        style={{width:"7em",margin:"0px 5px"}}
                                        onClick={()=>setShowCheckItemModal(false)}
                                    >關閉</Button>
                                    <Button
                                        style={{width:"7em",margin:"0px 5px"}}
                                        className={"mainBtn"}
                                        onClick={()=> {
                                            let timeSelect = '',hotelSelect = '';
                                            if(item.project_type === 1 || item.project_type === 2){
                                                timeSelect = document.getElementById('checkItem_timesSelect').value;
                                                if(timeSelect != ""){
                                                    setShowCheckItemModal(false);
                                                    setShowAvailableCalendarModal(false);
                                                    bookIn(2,item.hotel_id,item.project_id,item.changeHotel,hotelSelect,timeSelect,item.project_type);
                                                }
                                            }else{
                                                hotelSelect = document.querySelectorAll('.checkItem_hotelSelect');
                                                setShowCheckItemModal(false);
                                                setShowAvailableCalendarModal(false);
                                                bookIn(2,item.hotel_id,item.project_id,item.changeHotel,hotelSelect,timeSelect,item.project_type);
                                            }
                                        }}
                                    >立即預訂</Button>
                                </div>
                            </div>
                        ))
                    }
                </Modal.Body>
            </Modal>

            {
                showRestBookingDetailModal && <RestBookingDetailModal restBookingDetailData={restBookingDetailData} showRestBookingDetailModal={showRestBookingDetailModal} onChangeShowRestBookingDetailModal={changeShowRestBookingDetailModal} checkInDate={restBooking_checkInDateRef.current.value} checkInTime={restBooking_checkInTimeRef.current.value} onBookIn={bookIn} />
            }

            { !showCheckout &&
            <>
                <Container className={"p-0 mb-5 mt-5"} fluid>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="img/carousel-1.jpg"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="img/carousel-2.jpg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Container>

                <Container className={"booking pb-3"} fluid style={{maxWidth:"1400px"}}>
                    <div className="bg-white shadow">
                        <Tabs
                            defaultActiveKey="booking"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="booking" title="快速訂房" style={{padding:"10px"}}>
                                <div className="row g-2">
                                    <div className="col-md-10">
                                        <div className="row g-2">
                                            <div className="col-md-2 search">
                                                <label className={"label"}>地區</label>
                                                <Form.Select defaultValue={"800"} ref={booking_districtRef}>
                                                    <option value="0">地區</option>
                                                    <option value="100">臺北</option>
                                                    <option value="200">基隆</option>
                                                    <option value="260">宜蘭</option>
                                                    <option value="300">新竹</option>
                                                    <option value="330">桃園</option>
                                                    <option value="360">苗栗</option>
                                                    <option value="400">臺中</option>
                                                    <option value="500">彰化</option>
                                                    <option value="540">南投</option>
                                                    <option value="600">嘉義</option>
                                                    <option value="630">雲林</option>
                                                    <option value="700">臺南</option>
                                                    <option value="800">高雄</option>
                                                    <option value="880">澎湖</option>
                                                    <option value="900">屏東</option>
                                                    <option value="950">臺東</option>
                                                    <option value="970">花蓮</option>
                                                    <option value="890">金門</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-2 search">
                                                <label className={"label"}>飯店</label>
                                                <Form.Select defaultValue={"0"} ref={booking_hotelRef}>
                                                    <option value="0">飯店</option>
                                                    {
                                                        hotelList.map((item,index)=>(
                                                            <option key={item.hotel_id} value={item.hotel_id}>{item.hotel_name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-2 search">
                                                <label className={"label"}>價格</label>
                                                <Form.Select defaultValue={"0"} ref={booking_priceOrderRef}>
                                                    <option value={"asc"}>價格：低至高</option>
                                                    <option value={"desc"}>價格：高至低</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-2 search">
                                                <label className={"label"}>人數</label>
                                                <Form.Select defaultValue={"2"} ref={booking_peopleRef}>
                                                    <option value="2">2人</option>
                                                    <option value="4">4人</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-2 search">
                                                <label className={"label"}>入住日期</label>
                                                <input
                                                    ref={booking_checkInRef}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    defaultValue={checkInDate}
                                                    type="date"
                                                    onFocus={(e)=> {
                                                        // e.target.min = booking_checkOutDateMin;

                                                        let booking_checkInDateMax = new Date(booking_checkOutRef.current.value);
                                                        booking_checkInDateMax = booking_checkInDateMax.setDate(booking_checkInDateMax.getDate()-1);
                                                        booking_checkInDateMax=new Date(booking_checkInDateMax).toJSON().slice(0, 10)
                                                        e.target.max = booking_checkInDateMax;


                                                    }}
                                                    // onChange={(e)=> {
                                                    //         booking_checkOutRef.current.min = e.target.value;
                                                    //     }
                                                    // }
                                                    // onBlur={(e)=>(e.target.type = "text")}

                                                    min={new Date().toJSON().slice(0, 10)}
                                                    // max={checkOutCurrentDate}
                                                    id="checkIn"/>
                                            </div>
                                            <div className="col-md-2 search">
                                                <label className={"label"}>退房日期</label>
                                                <input
                                                    ref={booking_checkOutRef}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    defaultValue={checkOutDate}
                                                    min={booking_checkInRef.current}
                                                    type="date"
                                                    onFocus={(e)=> {

                                                        let booking_checkOutDateMin = new Date(booking_checkInRef.current.value);
                                                        booking_checkOutDateMin = booking_checkOutDateMin.setDate(booking_checkOutDateMin.getDate()+1);
                                                        booking_checkOutDateMin=new Date(booking_checkOutDateMin).toJSON().slice(0, 10)

                                                        e.target.min = booking_checkOutDateMin;
                                                        // e.target.type = "date";
                                                    }}
                                                    id="checkOut"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <Button
                                            className={"w-100 mainBtn"}
                                            onClick={booking_search}
                                        >查詢</Button>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="restBooking" title="非住宿訂房" style={{padding:"10px"}}>
                                <div className="row g-2">
                                    <div className="col-md-10">
                                        <div className="row g-2">
                                            <div className="col-md-3 search">
                                                <label className={"label"}>地區</label>
                                                <Form.Select className={"form-control"} defaultValue={"0"} ref={restBooking_districtRef}>
                                                    <option value="0">地區</option>
                                                    <option value="100">臺北</option>
                                                    <option value="200">基隆</option>
                                                    <option value="260">宜蘭</option>
                                                    <option value="300">新竹</option>
                                                    <option value="330">桃園</option>
                                                    <option value="360">苗栗</option>
                                                    <option value="400">臺中</option>
                                                    <option value="500">彰化</option>
                                                    <option value="540">南投</option>
                                                    <option value="600">嘉義</option>
                                                    <option value="630">雲林</option>
                                                    <option value="700">臺南</option>
                                                    <option value="800">高雄</option>
                                                    <option value="880">澎湖</option>
                                                    <option value="900">屏東</option>
                                                    <option value="950">臺東</option>
                                                    <option value="970">花蓮</option>
                                                    <option value="890">金門</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-3 search">
                                                <label className={"label"}>飯店</label>
                                                <Form.Select className={"form-control"} defaultValue={"0"} ref={restBooking_hotelRef}>
                                                    <option value="0">飯店</option>
                                                    {
                                                        hotelList.map((item,index)=>(
                                                            <option key={item.hotel_id} value={item.hotel_id}>{item.hotel_name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-3 search">
                                                <label className={"label"}>入住日期</label>
                                                <input
                                                    ref={restBooking_checkInDateRef}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    defaultValue={checkInDate}
                                                    type="date"
                                                    min={new Date().toJSON().slice(0, 10)}
                                                    onFocus={(e)=> {
                                                        // e.target.type = "date";
                                                    }}
                                                    // onBlur={(e)=>(e.target.type = "text")}
                                                    id="checkIn"/>
                                            </div>
                                            <div className="col-md-3 search">
                                                <label className={"label"}>入住時間</label>
                                                <input
                                                    ref={restBooking_checkInTimeRef}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    defaultValue={new Date().toTimeString().slice(0, 5)}
                                                    type="time"
                                                    onFocus={(e)=> {
                                                        // e.target.type = "time";
                                                        // e.target.step = 1800;
                                                        console.log(new Date())
                                                        console.log(new Date().toTimeString().slice(0, 5))
                                                    }}
                                                    // onBlur={(e)=>(e.target.type = "text")}
                                                    id="checkOut"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <Button
                                            className={"w-100 mainBtn"}
                                            onClick={restBooking_search}
                                        >查詢</Button>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="project" title="專案查詢" style={{padding:"10px"}}>
                                <div className="row g-2">
                                    <div className="col-md-10">
                                        <div className="row g-2">
                                            <div className="col-md-6 search">
                                                <label className={"label"}>飯店</label>
                                                <Form.Select defaultValue={"0"} ref={project_hotelRef}>
                                                    <option value="0">飯店</option>
                                                    {
                                                        hotelList.map((item,index)=>(
                                                            <option key={item.hotel_id} value={item.hotel_id}>{item.hotel_name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </div>
                                            <div className="col-md-6 search">
                                                <label className={"label"}>價格</label>
                                                <Form.Select defaultValue={"0"} ref={project_priceOrderRef}>
                                                    <option value={"asc"}>價格：低至高</option>
                                                    <option value={"desc"}>價格：高至低</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <Button
                                            className={"w-100 mainBtn"}
                                            onClick={project_search}
                                        >查詢</Button>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </>
            }

            {
                showNoData && <Container fluid style={{maxWidth:"1400px"}}>
                    <div style={{textAlign:"center"}} className={"mt-5 mb-5"}>
                        <FontAwesomeIcon icon={faCalendarXmark} style={{color:"#cf3838",fontSize:"8em"}} /><br/>
                        <span style={{color:"#cf3838",fontSize:"2em"}}>根據搜尋條件查無結果</span>
                    </div>

                    <div style={{textAlign:"center"}} className={"mt-5"}>
                        <span style={{color:"rgb(106, 218, 169)",fontSize:"2em"}}>或許可以更改搜尋條件</span>
                    </div>
                </Container>
            }

            {
                searchType === 0 && <Container fluid style={{maxWidth:"1400px"}} className={"fadeIn"}>
                    <HotelList hotel={hotelList} />
                </Container>
            }

            {
                searchType === 1 && <Container fluid style={{maxWidth:"1400px"}} className={"fadeIn"}>
                    <RoomTypeList roomTypeList={roomTypeList} detail={booking_detail} bookIn={bookIn} checkInDate={booking_checkInRef.current.value} checkOutDate={booking_checkOutRef.current.value} updateSearchType={updateSearchType}/>
                </Container>
            }

            {
                searchType === 2 && <Container fluid style={{maxWidth:"1400px"}} className={"mt-3 fadeIn"}>
                    <ProjectList projectList={projectList} onLoadAvailableCalendar={loadAvailableCalendar} project_detail={project_detail} checkInDate={checkInDate} />
                </Container>
            }

            {
                searchType === 3 && <Container fluid style={{maxWidth:"1400px"}} className={"mt-3 fadeIn"}>
                    <RestHoursList restHoursList={restBookingList} checkInDate={restBooking_checkInDateRef.current.value} checkInTime={restBooking_checkInTimeRef.current.value} restBooking_detail={restBooking_detail} bookIn={bookIn} />
                </Container>
            }

            {
                showCheckout && <Container fluid style={{maxWidth:"800px",marginTop:"6em"}} className={"fadeIn"}>
                    {
                        checkoutPara[0].type === 1 && checkoutPara.map((item,index)=>(
                            <div key={index} className={"row"}>
                                <div className="col-12 fadeInUp mt-3 mb-4">
                                    <div className="room-item shadow rounded overflow-hidden" style={{padding:"20px"}}>
                                        <div className={"h5"} style={{textAlign:"center"}}>遠悅飯店高雄愛河館<br/>快速訂房</div>
                                        <hr/>
                                        <div className={"row mt-3"}>
                                            <div className={"col-lg-5"}>
                                                <img src="https://fakeimg.pl/350x200/000/ccc?text=Photo" style={{width:"100%"}} className={"mb-3"} />
                                            </div>
                                            <div className={"col-lg-7"}>
                                                <div className={"row"}>
                                                    <h5 className="mb-0">{item.room_type_name}</h5>
                                                    <div className="d-flex">
                                                        <div><FontAwesomeIcon icon={faPerson} /> 可入住 {item.room_type_sleep} 人</div><hr className={"separator"}/>
                                                        <div><FontAwesomeIcon icon={faBed} /> 1 張標準雙人床</div>
                                                    </div>
                                                    <div>入住時間：<br/>
                                                        入住當天下午15:00過後 ~ 退房當天早上11:00之前
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row mt-3"}>
                                            <div className={"col-6"}>
                                                <div style={{backgroundColor: "#aad7ff",textAlign: "center",padding: "10px 0px"}}>
                                                    入住<br/>{item.checkInDatetime}
                                                </div>
                                            </div>
                                            <div className={"col-6"}>
                                                <div style={{backgroundColor: "#fd9494",textAlign: "center",padding: "10px 0px"}}>
                                                    退房<br/>{item.checkOutDatetime}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row mt-3"} style={{textAlign:"right"}}>
                                            <div><FontAwesomeIcon icon={faMoon} /> 入住 1 晚，總共<span className={"h3"}>NTD {item.room_total_price}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    {
                        checkoutPara[0].type === 2 && checkoutPara.map((item, index) => (
                            <div key={index} className={"row"}>
                                <div className="col-12 fadeInUp mt-3 mb-4">
                                    <div className="room-item shadow rounded overflow-hidden" style={{padding:"20px"}}>
                                        <div className={"h5"} style={{textAlign:"center"}}>遠悅飯店高雄愛河館<br/>專案訂房</div>
                                        <hr/>
                                        <div className={"row mt-3"}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <div className={"mb-3"}>
                                                        <h5>{item.project_name}</h5>
                                                    </div>
                                                    <div className={"mb-3"}>{item.project_content.slice(0,-1)}</div>
                                                    <div className={"mb-3"}>{item.project_introduce}</div>
                                                    {/*<div>*/}
                                                    {/*    <div style={{display:"inline-block"}}>專案販售期間：2024-04-04 ~ 2024-07-31</div>&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                                    {/*    <div style={{display:"inline-block"}}>專案使用期間：2024-04-03 ~ 2024-04-28</div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            item.changeHotel === 2 &&

                                            <div className={"row"}>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#aad7ff",textAlign: "center",padding: "10px 0px"}}>
                                                        入住<br/>{item.checkInDatetime}
                                                    </div>
                                                </div>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#fd9494",textAlign: "center",padding: "10px 0px"}}>
                                                        退房<br/>{item.checkOutDatetime}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            item.changeHotel === 1 &&

                                            <div className={"row"}>
                                                <div className={"table-responsive col-12 sameHotelTable"}>
                                                    <table className={"table table-bordered mt-3"}>
                                                        <thead>
                                                        <tr className={"d-flex theHeader"}>
                                                            <th scope="col" className={"col-4"}>入住日期</th>
                                                            <th scope="col" className={"col-4"}>退房日期</th>
                                                            <th scope="col" className={"col-4"}>入住飯店</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            item.hotelSelect.map((item2,index)=>(

                                                                <tr key={index} className={"d-flex"}>
                                                                    <td className={"col-4"}>{item2.checkInDate}</td>
                                                                    <td className={"col-4"}>{item2.checkOutDatetime}</td>
                                                                    <td className={"col-4"}>{item2.hotelName}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.changeHotel === 0 && <div className={"row"}>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#aad7ff",textAlign: "center",padding: "10px 0px"}}>
                                                        入住<br/>{checkItemData[0].checkInDatetime}
                                                    </div>
                                                </div>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#fd9494",textAlign: "center",padding: "10px 0px"}}>
                                                        退房<br/>{checkItemData[0].checkOutDatetime}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className={"row mt-3"} style={{textAlign:"right"}}>
                                            <div>專案價<span className={"h3"}>NTD {item.project_price}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    {
                        checkoutPara[0].type === 3 && checkoutPara.map((item, index) => (
                            <div key={index} className={"row"}>
                                <div className="col-12 fadeInUp mt-3 mb-4">
                                    <div className="room-item shadow rounded overflow-hidden" style={{padding:"20px"}}>
                                        <div className={"h5"} style={{textAlign:"center"}}>遠悅飯店高雄愛河館<br/>非住宿訂房</div>
                                        <hr/>
                                        <div className={"row mt-3"}>
                                            <div className={"col-lg-5"}>
                                                <div style={{textAlign:"center",color:"rgb(106, 218, 169)"}}>
                                                    <FontAwesomeIcon style={{fontSize:"7em"}} icon={faClock} />
                                                </div>
                                            </div>
                                            <div className={"col-lg-7"} style={{display: "flex", alignItems: "center"}}>
                                                <div>
                                                    <h5 className="">非住宿訂房 - {item.hours}個小時</h5>
                                                    <div style={{fontSize:"1.2em",fontWeight:"bold",color:"red"}}>
                                                        <FontAwesomeIcon icon={faCircleExclamation} /> 由系統安排房間
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row mt-3"}>
                                            <div className={"col-6"}>
                                                <div style={{backgroundColor: "#aad7ff",textAlign: "center",padding: "10px 0px"}}>
                                                    入住<br/>{item.checkInDatetime}
                                                </div>
                                            </div>
                                            <div className={"col-6"}>
                                                <div style={{backgroundColor: "#fd9494",textAlign: "center",padding: "10px 0px"}}>
                                                    退房<br/>{item.checkOutDatetime}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row mt-3"} style={{textAlign:"right"}}>
                                            <div><FontAwesomeIcon icon={faClock} /> 入住 {item.hours} 個小時，總共<span className={"h3"}>NTD {item.price}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <div className={"row g-4 mb-4"}>
                        <div className="col-12 fadeInUp">
                            <div className="room-item shadow rounded overflow-hidden">
                                <div className="p-4">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className={"mb-3"}>
                                                <h5 style={{display:"inline-block"}}>*付款方式</h5>
                                            </div>
                                            <div className={"mb-3"}>
                                                <div style={{display:"inline-block"}}>藍星金流-信用卡一次付清</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row g-4 mb-4"}>
                        <div className="col-12 fadeInUp">
                            <div className="room-item shadow rounded overflow-hidden">
                                <div className="p-4">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className={"mb-3"}>
                                                <h5 style={{display:"inline-block"}}>*發票資訊(發票將在退房時開立並寄至訂房人Email信箱)</h5>
                                            </div>
                                            <div className={"mb-3"}>
                                                <div>
                                                    <input type="radio" id="invoice_person" name="invoiceRadio" value="1" className={"form-check-input"}
                                                           onChange={(e)=>handleInvoiceSelect(e)} defaultChecked/>
                                                    <label htmlFor="invoice_person">&nbsp;個人發票</label>
                                                    {/*<div id={"invoiceInputWrap1"} className={"search mb-3 display_none invoiceInputWrap"}>*/}
                                                    {/*    手機載具：<input*/}
                                                    {/*        id={"invoiceInput_carrier"}*/}
                                                    {/*        placeholder="例如/ABCD123"*/}
                                                    {/*        className={"form-control form-item invoiceInput"}*/}
                                                    {/*        type="text"*/}
                                                    {/*        required={true}*/}
                                                    {/*    /><br/>*/}
                                                    {/*    <span style={{"color":"red","fontWeight":"800"}}>(手機載具無填寫則存入ezPay電子發票載具)</span>*/}
                                                    {/*</div>*/}
                                                </div>

                                                <div>
                                                    <input type="radio" id="invoice_company" name="invoiceRadio" value="2" className={"form-check-input"}
                                                           onChange={(e)=>handleInvoiceSelect(e)}/>
                                                    <label htmlFor="invoice_company">&nbsp;公司發票</label>
                                                    <div id={"invoiceInputWrap2"} className={"search display_none invoiceInputWrap"}>
                                                        *統一編號：<input
                                                            id={"invoiceInput_taxNumber"}
                                                            placeholder="請填入統一編號"
                                                            className={"form-control form-item invoiceInput mb-1"}
                                                            type="text"
                                                            required={true}
                                                        /><br/>
                                                        *公司抬頭：<input
                                                            id={"invoiceInput_companyName"}
                                                            placeholder="請填入公司抬頭"
                                                            className={"form-control form-item invoiceInput"}
                                                            type="text"
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                                <br/>
                                                依統一發票使用辦法規定：發票一經開立不得任意更改。
                                                {/*<div>*/}
                                                {/*    <input type="radio" id="invoice_donate" name="invoiceRadio" value="3" className={"form-check-input"}*/}
                                                {/*           onChange={(e)=>handleInvoiceSelect(e)}/>*/}
                                                {/*    <label htmlFor="invoice_donate">&nbsp;捐贈發票</label>*/}
                                                {/*    <div id={"invoiceInputWrap3"} className={"search mb-3 display_none invoiceInputWrap"}>*/}
                                                {/*        *捐贈碼：<input*/}
                                                {/*            id={"invoiceInput_donateNumber"}*/}
                                                {/*            placeholder="請填入捐贈碼"*/}
                                                {/*            className={"form-control form-item invoiceInput"}*/}
                                                {/*            type="text"*/}
                                                {/*            required={true}*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <form  id={"checkoutForm"} method={"post"} action={"https://ccore.newebpay.com/MPG/mpg_gateway"}>
                        <div className={"row g-4 mb-4"}>
                            <div className="col-12 fadeInUp">
                                <div className="room-item shadow rounded overflow-hidden">
                                    <div className="p-4">
                                        <div className={"mb-3"}>
                                            <h5 style={{display:"inline-block"}}>*訂房人基本資料</h5>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>*訂房人姓氏</label>
                                                <input
                                                    id={"purchaser_lastName"}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    type="text"
                                                    required={true}
                                                />
                                            </div>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>*訂房人名字</label>
                                                <input
                                                    id={"purchaser_firstName"}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    type="text"
                                                    required={true}
                                                />
                                            </div>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>*訂房人電話</label>
                                                <input
                                                    id={"purchaser_phoneNumber"}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    type="text"
                                                    required={true}
                                                />
                                            </div>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>*訂房人Email</label>
                                                <input
                                                    id={"purchaser_email"}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    type={"email"}
                                                    required={true}
                                                />
                                            </div>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>*訂房人姓別</label>
                                                <Form.Select
                                                    id={"purchaser_sex"}
                                                    required={true}>
                                                    <option value={"0"} disabled={true}>---請選擇---</option>
                                                    <option value={"1"}>男</option>
                                                    <option value={"2"}>女</option>
                                                </Form.Select>
                                            </div>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>訂房人國籍</label>
                                                <Form.Select id={"purchaser_country"}>
                                                    {
                                                        countiesList.map((country, index) => (
                                                            <option key={country.value} value={country.value}>{country.content}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </div>
                                            <div className={"col-md-4 search mb-3"}>
                                                <label className={"label"}>訂房人身分證字號</label>
                                                <input
                                                    id={"purchaser_idNumber"}
                                                    placeholder=""
                                                    className={"form-control form-item"}
                                                    type="text"
                                                    required={true}
                                                />
                                            </div>
                                            <div style={{display: "flex", alignItems: "center"}} className={"col-md-4 search mb-3"}>
                                                <input
                                                    id={"different"}
                                                    className={"form-item"}
                                                    type="checkbox"
                                                    style={{width:"1.2em",height:"1.2em"}}
                                                    onChange={(e)=>e.target.checked?setShowCheckInDetail(true):setShowCheckInDetail(false)
                                                    }
                                                />&nbsp;
                                                <label>訂房人與入住人不同</label>
                                            </div>
                                            <div className={"col-12 search mb-3"}>
                                                <label className={"label"}>備註</label>
                                                <textarea
                                                    id={"note"}
                                                    className={"form-control form-item"}
                                                    rows="4"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            showCheckInDetail && <div className={"row g-4 mb-4 fadeIn"}>
                                <div className="col-12 fadeInUp">
                                    <div className="room-item shadow rounded overflow-hidden">
                                        <div className="p-4">
                                            <div className={"mb-3"}>
                                                <h5 style={{display:"inline-block"}}>*入住人基本資料</h5>
                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-md-4 search mb-3"}>
                                                    <label className={"label"}>*入住人姓氏</label>
                                                    <input
                                                        id={"occupier_lastName"}
                                                        placeholder=""
                                                        className={"form-control form-item"}
                                                        type="text"
                                                        required={true}
                                                        defaultValue={""}
                                                    />
                                                </div>
                                                <div className={"col-md-4 search mb-3"}>
                                                    <label className={"label"}>*入住人名字</label>
                                                    <input
                                                        id={"occupier_firstName"}
                                                        placeholder=""
                                                        className={"form-control form-item"}
                                                        type="text"
                                                        required={true}
                                                        defaultValue={""}
                                                    />
                                                </div>
                                                <div className={"col-md-4 search mb-3"}>
                                                    <label className={"label"}>*入住人電話</label>
                                                    <input
                                                        id={"occupier_phoneNumber"}
                                                        placeholder=""
                                                        className={"form-control form-item"}
                                                        type="text"
                                                        required={true}
                                                        defaultValue={""}
                                                    />
                                                </div>
                                                <div className={"col-md-4 search mb-3"}>
                                                    <label className={"label"}>*入住人姓別</label>
                                                    <Form.Select id={"occupier_sex"}>
                                                        <option value={"0"} disabled={true}>---請選擇---</option>
                                                        <option value={"1"}>男</option>
                                                        <option value={"2"}>女</option>
                                                    </Form.Select>
                                                </div>
                                                <div className={"col-md-4 search mb-3"}>
                                                    <label className={"label"}>入住人國籍</label>
                                                    <Form.Select id={"occupier_country"}>
                                                        {
                                                            countiesList.map((country, index) => (
                                                                <option key={country.value} value={country.value}>{country.content}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={"display_none"}>
                            MID: <input name="MerchantID" ref={checkout_MIDRef} readOnly />
                            Version: <input name="Version" ref={checkout_VersionRef} readOnly />
                            TradeInfo: <input name="TradeInfo" ref={checkout_TradeInfoRef} readOnly />
                            TradeSha: <input name="TradeSha" ref={checkout_TradeShaRef} readOnly />
                        </div>

                        <Button
                            className={"col-5 btn btn-sm rounded mainBtn w-100 mb-4"}
                            onClick={(event)=>{
                                event.preventDefault();

                                let purchaser_lastName = document.getElementById('purchaser_lastName');
                                let purchaser_firstName = document.getElementById('purchaser_firstName');
                                let purchaser_phoneNumber = document.getElementById('purchaser_phoneNumber');
                                let purchaser_email = document.getElementById('purchaser_email');
                                let purchaser_sex = document.getElementById('purchaser_sex');
                                let purchaser_country = document.getElementById('purchaser_country');
                                let purchaser_idNumber = document.getElementById('purchaser_idNumber');
                                let different = document.getElementById('different');
                                let hasInvoice = document.getElementById('hasInvoice');
                                // let purchaser_GUINumber = document.getElementById('purchaser_GUINumber');
                                let note = document.getElementById('note');
                                let occupier_lastName = document.getElementById('occupier_lastName');
                                let occupier_firstName = document.getElementById('occupier_firstName');
                                let occupier_phoneNumber = document.getElementById('occupier_phoneNumber');
                                let occupier_sex = document.getElementById('occupier_sex');
                                let occupier_country = document.getElementById('occupier_country');
                                let invoiceRadios = document.getElementsByName('invoiceRadio');
                                let invoice_person = document.getElementById('invoice_person');
                                // let invoiceInput_carrier = document.getElementById('invoiceInput_carrier');
                                let invoice_company = document.getElementById('invoice_company');
                                let invoiceInput_taxNumber = document.getElementById('invoiceInput_taxNumber');
                                let invoiceInput_companyName = document.getElementById('invoiceInput_companyName');
                                let invoice_donate = document.getElementById('invoice_donate');
                                // let invoiceInput_donateNumber = document.getElementById('invoiceInput_donateNumber');
                                let tag = false;
                                let invoiceRadiosValue = '';

                                for (let i=0;i<invoiceRadios.length;i++){
                                    if(invoiceRadios[i].checked){
                                        tag = true;
                                        invoiceRadiosValue = invoiceRadios[i].value;
                                    }
                                }

                                if(!tag){
                                    invoiceRadios[0].focus();
                                    return;
                                }

                                if(invoice_company.checked == true && invoiceInput_taxNumber.value.trim() == ""){
                                    invoiceInput_taxNumber.focus();
                                    return;
                                }

                                if(invoice_company.checked == true && invoiceInput_companyName.value.trim() == ""){
                                    invoiceInput_companyName.focus();
                                    return;
                                }

                                if(purchaser_lastName.value.trim() === ""){
                                    purchaser_lastName.focus()
                                    return;
                                }

                                if(purchaser_firstName.value.trim() === ""){
                                    purchaser_firstName.focus()
                                    return;
                                }

                                if(purchaser_phoneNumber.value.trim() === ""){
                                    purchaser_phoneNumber.focus()
                                    return;
                                }

                                if(purchaser_email.value.trim() === ""){
                                    purchaser_email.focus()
                                    return;
                                }

                                if(purchaser_sex.value === "0"){
                                    purchaser_sex.focus()
                                    return;
                                }

                                // if(purchaser_country.value === "0"){
                                //     purchaser_country.focus()
                                //     return;
                                // }

                                if(different.checked === true && occupier_lastName.value.trim() === ""){
                                    occupier_lastName.focus()
                                    return;
                                }

                                if(different.checked === true && occupier_lastName.value.trim() === ""){
                                    occupier_lastName.focus()
                                    return;
                                }

                                if(different.checked === true && occupier_firstName.value.trim() === ""){
                                    occupier_firstName.focus()
                                    return;
                                }

                                if(different.checked === true && occupier_phoneNumber.value.trim() === ""){
                                    occupier_phoneNumber.focus()
                                    return;
                                }

                                if(different.checked === true && occupier_sex.value === "0"){
                                    occupier_sex.focus()
                                    return;
                                }

                                // if(hasInvoice.checked === true && purchaser_GUINumber.value.trim() === ""){
                                //     purchaser_GUINumber.focus()
                                //     return;
                                // }

                                // if(different.checked === true && occupier_country.value === "0"){
                                //     occupier_country.focus()
                                //     return;
                                // }

                                setShowSpinner(true);

                                console.log(purchaser_lastName);
                                console.log(occupier_lastName);
                                axios.post(`${apiServer}/smartBooking/smartBooking_saveBooking`,{
                                    itemId1:no,itemId2:checkoutPara[0],
                                    itemId3:{
                                        purchaser_lastName:purchaser_lastName.value,
                                        purchaser_firstName:purchaser_firstName.value,
                                        purchaser_phoneNumber:purchaser_phoneNumber.value,
                                        purchaser_email:purchaser_email.value,
                                        purchaser_sex:purchaser_sex.value,
                                        purchaser_country:purchaser_country.value,
                                        purchaser_idNumber:purchaser_idNumber.value,
                                        different:different.checked,
                                        note:note.value,
                                        occupier_lastName:(occupier_lastName)?(occupier_lastName.value):"",
                                        occupier_firstName:(occupier_firstName)?(occupier_firstName.value):"",
                                        occupier_phoneNumber:(occupier_phoneNumber)?(occupier_phoneNumber.value):"",
                                        occupier_sex:(occupier_sex)?(occupier_sex.value):"0",
                                        occupier_country:(occupier_country)?(occupier_country.value):"0",
                                        invoiceType:invoiceRadiosValue,
                                        // invoiceInput_carrier:(invoiceInput_carrier)?(invoiceInput_carrier.value):"",
                                        invoiceInput_taxNumber:(invoiceInput_taxNumber)?(invoiceInput_taxNumber.value):"",
                                        invoiceInput_companyName:(invoiceInput_companyName)?(invoiceInput_companyName.value):"",
                                        // invoiceInput_donateNumber:(invoiceInput_donateNumber)?(invoiceInput_donateNumber.value):"",
                                    }
                                })
                                    .then((response) =>{
                                        if(response.status === 200){
                                            if(response.data.length != 0){
                                                console.log(response.data);
                                                if(response.data){
                                                    document.getElementById('checkoutForm').submit();
                                                    setShowSpinner(false);
                                                }
                                            }else{
                                                setShowSpinner(false);
                                            }
                                        }else{
                                            console.log("Server Error")
                                        }
                                    })
                                    .catch((error) => {
                                        setShowSpinner(false);
                                        console.log(error);
                                    });
                            }}
                            type={"submit"}
                        >結帳</Button>

                    </form>
                    <Button
                        className={"col-5 btn btn-sm rounded closeBtn w-100 mb-4"}
                        onClick={()=>{
                            setSearchType(0);
                            setShowCheckout(false);
                            no = '';
                            window.document.body.scrollTop = 0;
                            window.document.documentElement.scrollTop = 0;
                        }}
                    >取消</Button>
                </Container>
            }
        </Container>
    );
}

export default Home;